import React, { useContext, useEffect, useRef, useState } from 'react'
// import { useLocation, Route, useRouteMatch } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { Text, Flex, Heading, Button, ChevronRightIcon, Link } from '@sparkpointio/sparkswap-uikit'
import Carousel from 'react-elastic-carousel'
import PageSection from '../styles/Layout'
import { StyledContainer, ImageContainer, ButtonContainer } from '../styles/Containers'
import { Colors } from '../styles/Layout/Colors'
import { Upnext } from './types'

const CustomStyledLink = styled.a`
  text-decoration: none;
  color: ${Colors.accent3};

  &:hover,
  &:active {
    text-decoration: underline;
  }
  &:visited {
    color: pink;
  }
  &:active {
    color: white;
  }
`

const DynamicImageContainer = styled.div`
  width: 100%;
`

const IDODynamicImageContainer = styled.div`
  width: 90%;
  @media (max-width: 1366px) {
    width: 100%;
    margin: auto auto 60px auto;
  }
`

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const BG = styled(StyledContainer)`
  background: url('/images/Website/hero-bg.png');
  background-color: #161c26;
  background-blend-mode: overlay;
  padding: 3rem 0 0 0;
  justify-content: start;
  position: relative;
`

const HomeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5.5em 0em auto 0em;
  @media screen and (max-width: 1920px) {
    min-height: 60vmin;
  }
  @media screen and (max-width: 1669px) {
    margin-left: 5em;
    margin-right: 5em;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    gap: 5em;
    margin: 3em 0em 3em 0em;
  }
`

const IDOHomeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5.5em 0em auto 5.5em;
  @media screen and (max-width: 1920px) {
    min-height: 60vmin;
  }
  @media screen and (max-width: 1669px) {
    margin-left: 5em;
    margin-right: 5em;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    gap: 5em;
    margin: 3em 0em 3em 0em;
  }
`

const StyledHeading = styled(Heading)`
  font-size: 4.5rem;
  @media screen and (max-width: 1024px) {
    font-size: 2rem;
  }
`

const HeadingContainer = styled(Flex)`
  width: auto;
`

const LaunchButton = styled(Button)`
  background: linear-gradient(to right, #0071bc, #39beec);
  border-radius: 6px;
`

const SubHeaderText = styled(Text)`
  font-family: 'Monda', sans-serif;
  font-size: 1.5em;
  word-spacing: auto;
  margin: 1em 0em 1em 0em;

  @media screen and (max-width: 1366px) {
    font-size: 1.3em;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.3em;
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`

const LaroSubHeaderText = styled(Text)`
  font-family: 'Monda', sans-serif;
  font-size: 1.5em;
  word-spacing: auto;
  margin: 1em 0em 1em 0em;
  width: 70%;
  @media screen and (max-width: 1366px) {
    font-size: 1.3em;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1em;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.3em;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    font-size: 1em;
  }
`

const ImageDiv = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

const VolumeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 3em;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`

const VolumeContainer = styled.div`
  margin: 3em 0px;
  @media (max-width: 500px) {
    margin: 1.5em 0 -1.5em 0;
  }
`

const VolumeTitle = styled.p`
  color: ${Colors.accent3};
`

const VolumeValue = styled.p`
  color: ${Colors.text1};
  font-size: 3em;

  @media screen and (max-width: 768px) {
    font-size: 2em;
  }
`

const CustomHeading = styled.div`
  font-family: 'Quatro';
  color: ${Colors.accent3};
  font-size: 5em;

  @media screen and (max-width: 1617px) {
    font-size: 4em;
  }

  @media screen and (max-width: 1366px) {
    font-size: 3em;
  }

  @media screen and (max-width: 1024px) {
    font-size: 2em;
  }

  @media screen and (max-width: 768px) {
    font-size: 3em;
  }

  @media screen and (max-width: 500px) {
    font-size: 2.5em;
    margin: 0.5em;
  }
`

const StyledSpan = styled.span`
  font-weight: bold;
  font-family: quatro;
  color: ${Colors.accent3};
`

const Website: React.FC = () => {
  const theme = useContext(ThemeContext)
  const multisenderHero = <img src="images/Website/multisender-hero.png" alt="multiHero" />

  // Data for upcoming IDO carousel page
  const upcomingIdoTitle = 'Anito Legends'
  const upcomingIdoToken = 'LARO'
  const upcomingIdoDate = 1661857200000 // Aug 30, 2022 11:00 GMT
  const upcomingIdoBg = <img src="images/Website/LaroHero.png" alt="LaroHero" />

  const futuresLaunch = 1685617200000 // June 1, 2023 7:00 PM local

  // IDO epoch to date
  const milliToDate = (milliseconds) => {
    const date = new Date(0)
    date.setMilliseconds(milliseconds)

    return date
  }

  // IDO epoch date to string
  const dateStartString = milliToDate(upcomingIdoDate).toUTCString()

  /**
   * Checks if futures has launched
   * @returns {boolean} True if futures has launched, false otherwise
   */
  const isFuturesLaunched = () => {
    const currentDate = new Date()
    const launchDate = milliToDate(futuresLaunch)

    return launchDate < currentDate
  }

  const isUpcomingIDOCompleted = () => {
    const currentDate = new Date()
    const idoStartDate = milliToDate(upcomingIdoDate)

    return idoStartDate < currentDate
  }

  const ComingUpList = [
    {
      data: 'Improved UI/UX',
    },
    {
      data: 'New Info Page',
    },
    {
      data: 'New Reward System',
    },
    {
      data: 'SparkyVIP',
    },
    {
      data: 'Liquidity Lockers',
    },
    {
      data: 'Limit Orders',
    },
    {
      data: 'Perpetual',
    },
  ]

  const ComingupContainer = ({ data }: Upnext) => {
    return (
      <>
        <ChevronRightIcon color={Colors.accent3} />
        {data}
        <br />
      </>
    )
  }

  const [totalLiquidityAndVolumeUSD, setTotalLiquidityAndVolumeUSD] = useState({
    totalLiquidity: 0,
    totalVolume: 0,
  })
  const [totalVolumeUSD, setTotalVolumeUSD] = useState(0)

  const date = new Date()
  const timestampNow = Math.floor(Date.now() / 1000)
  const timestamp24hAgo = Math.floor(date.setDate(date.getDate() - 1) / 1000)
  const apiEndpoint = 'https://api.thegraph.com/subgraphs/name/sparkpointio/spark-swap'
  const apiBlockEndpoint = 'https://api.thegraph.com/subgraphs/name/aldrickb/test2sparkblock'

  const getGraphQLData = async () => {
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: `
            {
              uniswapFactories(first: 1) {
                totalLiquidityUSD
                totalVolumeUSD
              }
            }
          `,
        }),
      })

      const { data } = await response.json()
      const [result] = data.uniswapFactories

      setTotalLiquidityAndVolumeUSD({
        totalLiquidity: result?.totalLiquidityUSD,
        totalVolume: result?.totalVolumeUSD,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getGraphQLDataBlock = async (block) => {
    try {
      const res = await fetch(apiEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: `{
            uniswapFactories(first: 1 block: { number: ${block} }) {
              totalVolumeUSD
            }
          }`,
        }),
      })
      const data = await res.json()

      const calculatedVolume = totalLiquidityAndVolumeUSD.totalVolume - data?.data?.uniswapFactories[0]?.totalVolumeUSD
      const volumeToSet = calculatedVolume >= 0 ? calculatedVolume : 0

      setTotalVolumeUSD(volumeToSet)
    } catch (err) {
      console.error(err)
    }
  }

  const getGraphQLBlocks = async () => {
    try {
      const res = await fetch(apiBlockEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: `{
            blocks(first: 1 orderBy: timestamp orderDirection: asc where: { timestamp_gt: ${timestamp24hAgo} timestamp_lt: ${timestampNow}}) {
              id
              number
              timestamp
            }
          }`,
        }),
      })
      const data = await res.json()
      const computedVolume = data?.data?.blocks[0]?.number
      getGraphQLDataBlock(computedVolume)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getGraphQLData()
    getGraphQLBlocks()
  })

  const VolumeInfo = () => {
    return (
      <VolumeWrapper>
        <VolumeContainer>
          <VolumeTitle>24H Volume</VolumeTitle>
          <VolumeValue>
            {totalVolumeUSD !== 0
              ? `$${totalVolumeUSD
                  .toString()
                  .split('.')[0]
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              : 'Awaiting data...'}
          </VolumeValue>
        </VolumeContainer>
      </VolumeWrapper>
    )
  }

  const Perpetual = () => {
    const futuresLaunched = isFuturesLaunched()

    return (
      <IDOHomeWrapper>
        <StyledFlex>
          <SubHeaderText>
            {futuresLaunched ? 'UNLEASH THE FUTURE OF TRADING:' : 'REVOLUTIONARY TRADING EXPERIENCE:'}
          </SubHeaderText>
          <CustomHeading>
            <StyledSpan>
              {futuresLaunched ? 'SparkSwap Futures is Now Live!' : 'SparkSwap Futures - Launching June 1st!'}
            </StyledSpan>
          </CustomHeading>
          <div>
            <SubHeaderText>
              Trade on our Perpetual Futures Exchange powered by ApolloX,&nbsp;
              <CustomStyledLink href="https://perp.sparkswap.finance/">SparkSwap Futures!</CustomStyledLink>
              <br />
              <Button
                mt="2em"
                style={{ background: 'linear-gradient(to right, #0071BC, #39BEEC)', borderRadius: '6px' }}
                as="a"
                href="https://medium.com/theecosystem/sparkswap-partners-with-apollox-to-launch-a-perpetual-futures-trading-revolutionizing-3c7377d37052"
              >
                <Text>More info here</Text>
              </Button>
            </SubHeaderText>
          </div>
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <IDODynamicImageContainer>
            <img src="images/Website/PerpImage.png" alt="PerpetualIcon" />
          </IDODynamicImageContainer>
        </ImageDiv>
      </IDOHomeWrapper>
    )
  }

  const SparkDeFiNowSwap = () => {
    return (
      <HomeWrapper>
        <StyledFlex>
          <CustomHeading>
            <StyledSpan>Spark</StyledSpan>
            <StyledSpan style={{ color: '#0071BC' }}>DeFi</StyledSpan> is now <br />
            <StyledSpan>Spark</StyledSpan>
            <StyledSpan style={{ color: '#0071BC' }}>Swap</StyledSpan>!
          </CustomHeading>
          <div>
            <SubHeaderText bold>Better, Faster, and more Powerful. </SubHeaderText>
            <SubHeaderText style={{ fontSize: '1.3em' }}>
              Welcome to SparkSwap DeFi Platform. <br />
              <Button
                as="a"
                href="https://medium.com/theecosystem/sparkswap-v2-to-the-second-power-fad854707f75"
                style={{
                  background: 'linear-gradient(to right, #0071BC, #39BEEC)',
                  borderRadius: '6px',
                  margin: '2em 0px',
                }}
              >
                <Text>Read the Press Release</Text>
              </Button>
            </SubHeaderText>
          </div>
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <DynamicImageContainer>
            <img src="images/Website/HeroSparkSwapIcon.png" alt="HeroSparkSwapIcon" />
          </DynamicImageContainer>
        </ImageDiv>
      </HomeWrapper>
    )
  }

  const DexIsComing = () => {
    return (
      <HomeWrapper>
        <StyledFlex>
          <CustomHeading>
            A New and Improved <br />
            <StyledSpan>Spark</StyledSpan>
            <StyledSpan style={{ color: '#0071BC' }}>Swap</StyledSpan>
            <StyledSpan style={{ color: '#FFFFFF' }}> DEX</StyledSpan> <br /> is coming!
          </CustomHeading>
          <SubHeaderText>
            An improved UI+UX, upgraded Info Page, <br />
            Sparky VIP Club perks integration, <br />
            Liquidity Lockers, Limit Orders, and <br />
            perpetual, all coming this 2023! <br />
            <Button mt="2em" disabled style={{ borderRadius: '6px' }}>
              <Text>Coming this 2023!</Text>
            </Button>
          </SubHeaderText>
          {/* <VolumeContainer>
              <VolumeTitle>Total Liquidity</VolumeTitle>
              <VolumeValue>{totalLiquidityAndVolumeUSD.totalLiquidity !== 0 ? `$${totalLiquidityAndVolumeUSD.totalLiquidity.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '$0.00'}</VolumeValue>
            </VolumeContainer> */}
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <DynamicImageContainer>
            <img src="images/Website/Dex2Icon.png" alt="Dex2Icon" />
          </DynamicImageContainer>
        </ImageDiv>
      </HomeWrapper>
    )
  }

  const UpcomingIdo = () => {
    return (
      <IDOHomeWrapper>
        <StyledFlex>
          <SubHeaderText>UPCOMING IDO ON SPARKLAUNCH </SubHeaderText>
          <CustomHeading>
            <StyledSpan>
              {upcomingIdoTitle} IDO <br /> will launch soon!
            </StyledSpan>
          </CustomHeading>
          <div>
            <LaroSubHeaderText>
              {upcomingIdoToken} token will be live on {dateStartString}. Stack your{' '}
              <CustomStyledLink href="https://sparkswap.finance/#/swap?outputCurrency=0xC3440c10c4F36f354eB591B19FafB4906d449B75">
                SRKb
              </CustomStyledLink>{' '}
              and{' '}
              <CustomStyledLink href="https://sparkswap.finance/#/swap?outputCurrency=0x37ac4d6140e54304d77437a5c11924f61a2d976f">
                SFUEL
              </CustomStyledLink>{' '}
              tokens, participate and secure an allocation during the sale. <br />
              <Button
                as="a"
                href="https://app.sparkswap.finance/#/launch"
                style={{
                  background: 'linear-gradient(to right, #0071BC, #39BEEC)',
                  borderRadius: '6px',
                  margin: '2em 0px',
                }}
              >
                <Text>Go to SparkLaunch</Text>
              </Button>
            </LaroSubHeaderText>
          </div>
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <IDODynamicImageContainer>{upcomingIdoBg}</IDODynamicImageContainer>
        </ImageDiv>
      </IDOHomeWrapper>
    )
  }

  const CompletedIdo = () => {
    return (
      <IDOHomeWrapper>
        <StyledFlex>
          <SubHeaderText>NEWEST IDO ON SPARKLAUNCH </SubHeaderText>
          <CustomHeading>
            <StyledSpan>
              {upcomingIdoTitle} IDO! <br />
            </StyledSpan>
          </CustomHeading>
          <div>
            <LaroSubHeaderText>
              {upcomingIdoTitle} token launched last {dateStartString}. <br />
              <Button
                as="a"
                href="https://app.sparkswap.finance/#/launch"
                style={{
                  background: 'linear-gradient(to right, #0071BC, #39BEEC)',
                  borderRadius: '6px',
                  margin: '2em 0px',
                }}
              >
                <Text>Go to SparkLaunch</Text>
              </Button>
            </LaroSubHeaderText>
          </div>
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <IDODynamicImageContainer>{upcomingIdoBg}</IDODynamicImageContainer>
        </ImageDiv>
      </IDOHomeWrapper>
    )
  }

  const Multisender = () => {
    return (
      <IDOHomeWrapper>
        <StyledFlex>
          {/* <SubHeaderText>SparkSwap Multisender</SubHeaderText> */}
          <CustomHeading>
            <StyledSpan>
              The easiest way <br /> to airdrop your tokens!
              <br />
            </StyledSpan>
          </CustomHeading>
          <div>
            <LaroSubHeaderText>
              Send tokens to thousands of addresses in just a couple of seconds! Use your{' '}
              <CustomStyledLink href="https://sparkswap.finance/#/swap?outputCurrency=0xC3440c10c4F36f354eB591B19FafB4906d449B75">
                SRKb
              </CustomStyledLink>{' '}
              and{' '}
              <CustomStyledLink href="https://sparkswap.finance/#/swap?outputCurrency=0x37ac4d6140e54304d77437a5c11924f61a2d976f">
                SFUEL
              </CustomStyledLink>{' '}
              tokens for discounted rates. <br />
              <Button
                as="a"
                href="https://multisend.sparkswap.finance"
                style={{
                  background: 'linear-gradient(to right, #0071BC, #39BEEC)',
                  borderRadius: '6px',
                  margin: '2em 0px',
                }}
              >
                <Text>Go to Multisender</Text>
              </Button>
            </LaroSubHeaderText>
          </div>
          {/* {VolumeInfo()} */}
        </StyledFlex>
        <ImageDiv>
          <IDODynamicImageContainer>
            <Link href="https://multisend.sparkswap.finance">{multisenderHero}</Link>
          </IDODynamicImageContainer>
        </ImageDiv>
      </IDOHomeWrapper>
    )
  }

  const carouselRef = useRef<any>()
  let resetTimeout
  let timeout

  return (
    <PageSection direction="column" id="hero">
      <BG>
        <Carousel
          ref={carouselRef}
          isRTL={false}
          autoPlaySpeed={8000}
          onNextEnd={({ index }) => {
            const totalPages = carouselRef?.current?.getNumOfPages()
            clearTimeout(timeout)
            if (index === totalPages - 1) {
              timeout = setTimeout(() => {
                carouselRef?.current?.goTo(0)
              }, 8000)
            }
          }}
          enableAutoPlay
          showArrows={false}
        >
          {Perpetual()}
          {Multisender()}
          {/* {SparkDeFiNowSwap()} */}
          {DexIsComing()}
          {/* {isUpcomingIDOCompleted() ? CompletedIdo() : UpcomingIdo()} */}
        </Carousel>
      </BG>
    </PageSection>
  )
}

export default Website
