export const breakpoints = {
  Mobile: { 
      s: 320,
      m: 375,
      l: 425,
      xl: 600,
      tablet: 768
  },
  Desktop: {
      laptop: 1024,
      laptopL: 1440,
      laptopXL: 2560, // 4k
  }
}
