import { RoadmapConfig } from './types'

export const Roadmaps: RoadmapConfig = {
  // 2022: {
  //   Q1: [
  //     {
  //       value: 'SparkBridge: Refreshed UI/UX',
  //     },
  //     { value: 'SparkNFT Platform: New features' },
  //     { value: 'Quarterly Community Airdrop' },
  //     { value: 'New Trading Pairs in SparkSwap' },
  //   ],
  //   Q2: [
  //     {
  //       value: 'SparkSwap DEX V2',
  //     },
  //     { value: 'SFUEL Quarterly Community Airdrop' },
  //     { value: 'SparkSwap Quarterly Buy Back and Burn' },
  //   ],
  //   Q3: [
  //     // {
  //     //   value: 'SparkPool: New Feature',
  //     // },
  //     // {
  //     //   value: 'SparkSwap: SparkSwap VIP Club Integration',
  //     // },
  //     {
  //       value: 'SparkSwap DEX V2: New Feature',
  //     },
  //     {
  //       value: 'SparkSwap Community Airdrop',
  //     },
  //     // {
  //     //   value: 'SparkSwap Reward Token Buy Back and Burn',
  //     // },
  //     {
  //       value: 'New Trading pairs in SparkSwap V2',
  //     },
  //   ],
  //   Q4: [
  //     {
  //       value: 'SparkPool: New Feature',
  //     },
  //     {
  //       value: 'SparkLaunch: New Feature',
  //     },
  //     {
  //       value: 'SparkBridge: Multi-chain support',
  //     },
  //     {
  //       value: 'Quarterly Community Airdrop',
  //     },
  //     {
  //       value: 'Quarterly Buyback and Burn',
  //     },
  //     {
  //       value: 'New Trading Pairs in SparkSwap V2',
  //     },
  //   ],
  // },
  2023: {
    Q1: [
      {
        value: 'SparkSwap Multisender',
        items: [
          {
            value: 'Official Launch',
          },
          {
            value: 'Support for ETH and Goerli Chains',
          },
          {
            value: 'New Feature: View and Download Transaction Summary',
          },
          {
            value: 'New Feature: Free Multisending to Whitelisted Users',
          },
          {
            value: 'UX Improvements',
          },
        ],
      },
      {
        value: 'SparkBridge: Activation of SFUEL Fees',
      },
      {
        value: 'SparkSwap UI Kit: Launch of Version 1.4.1',
      },
    ],
    Q2: [
      {
        value: 'SparkSwap DEX v2',
        items: [
          {
            value: 'New Feature: SendX (previously SparkSend)',
          },
          {
            value: 'New Feature: Contact List Management in SendX',
          },
        ],
      },
      {
        value: 'SparkSwap Multisender',
        items: [
          {
            value: 'Support for Ethereum Network',
          },
          {
            value: 'New Feature: View and Download Transaction Summary',
          },
        ],
      },
      {
        value: 'SparkSwap Farms: App Enhancements',
      },
      {
        value: 'SparkSwap Futures',
        items: [
          {
            value: 'Open Beta Trading Competition',
          },
          {
            value: 'Official Launch',
          },
          {
            value: 'UX Enhancements',
          },
          {
            value: 'Support for Ethereum and Arbitrum Networks',
          },
        ],
      },
      {
        value: 'SparkBridge Enhancements',
        items: [
          {
            value: 'Updated fees and limits',
          },
          {
            value: 'Performance optimizations',
          },
        ],
      },
      {
        value: 'Quarterly Activities',
        items: [
          // {
          //   value: 'SparkSwap Community Airdrop'
          // },
          {
            value: 'Buy Back and Burn',
          },
        ],
      },
    ],
    recaps: [
      // {
      //   value: 'SparkPool: New liquidity staking pools',
      //   items: [
      //     {
      //       value: 'Staking app enhancements',
      //     },
      //   ],
      // },
      {
        value: 'SparkRewards: Self-Claim Airdrop Feature',
        items: [
          {
            value: 'Early Preview',
          },
          {
            value: 'Iterative Development',
          },
          {
            value: 'User Feedback and Testing',
          },
          {
            value: 'Documentation and Support',
          },
        ],
      },
      {
        value: 'SparkSwap DApps: Code Refactoring and Performance Optimization',
      },
      {
        value: 'SparkSwap WalletConnect V2 Integration ',
      },
      {
        value: 'SparkPoint Wallet and SparkSwap Merger',
      },
      {
        value: 'SparkPoint Wallet UI Updates',
      },
      {
        value: 'Quarterly Activities',
        items: [
          // {
          //   value: 'SparkSwap Community Airdrop'
          // },
          // {
          //   value: 'SparkSwap Buy Back and Burn',
          // },
          {
            value: 'New Trading Pairs in SparkSwap V2',
          },
        ],
      },
    ],
  },
  2024: {
    recaps: [
      // {
      //   value: 'SparkChain Blockchain Phase 2 and onwards'
      // },
      {
        value: 'SparkSwap Multisender: Support for Polygon Network',
      },
      {
        value: 'SparkRewards: Self-Claim Airdrop Feature',
        items: [
          {
            value: 'Iterative Development - Beta Phase',
          },
          {
            value: 'UI/UX Enhancements',
          },
          {
            value: 'Bug Fixes and Optimization',
          },
          {
            value: 'User Feedback and Testing',
          },
          {
            value: 'Security Auditing',
          },
          {
            value: 'Integration with Other Platforms',
          },
        ],
      },
      {
        value: 'SparkSwap Asset Management Protocol',
      },
      {
        value: 'SparkBridge: Support for additional blockchain networks',
      },
      {
        value: 'SparkLaunch: New Features',
      },
      {
        value: 'SparkSwap DEX: Support for additional blockchain networks',
      },
      {
        value: 'SparkPoint Wallet V2',
      },
      {
        value: 'SparkPoint Wallet New Feature: Import Custom ERC20 Tokens',
      },
      {
        value: 'P2P Lending and Borrowing',
      },
      {
        value: 'Quarterly Activities',
        items: [
          // {
          //   value: 'SparkSwap Community Airdrop'
          // },
          {
            value: 'SparkSwap Buy Back and Burn',
          },
          {
            value: 'New Trading Pairs in SparkSwap V2',
          },
        ],
      },
    ],
  },
}
