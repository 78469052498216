import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Button, Input as CInput } from '@sparkpointio/sparkswap-uikit'
import { Grid } from '@mui/material'
import Select from 'react-select'
import { dexTheme } from 'ThemeContext'
import useENS from '../../hooks/useENS'
import { useActiveWeb3React } from '../../hooks'
import { ExternalLink, TYPE } from '../Shared'
import { getEtherscanLink, shortenAddress } from '../../utils'
import { CONTACT_LIST_LIMIT } from '../../constants'

const { black: Black } = TYPE

const InputPanel = styled.div`
  //display: flex;
  //flex-flow: column nowrap;
  //position: relative;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  z-index: 1;
  //width: 100%;
`

const ContainerRow = styled.div<{ error: boolean }>`
  //display: flex;
  //justify-content: center;
  //align-items: center;
  border-radius: 1.25rem;
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.invertedContrast)};
  transition: border-color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')},
    color 500ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
`

const InputContainer = styled.div`
  //flex: 1;
  padding: 1rem;
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 1.25rem;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${({ theme }) => theme.colors.invertedContrast};
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.primary)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`

const RecipientInput = styled.input<{ error?: boolean }>`
  font-size: 0.9rem;
  //outline: none;
  border-color: white;
  //height: 33px;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.colors.tertiary};
  color: ${({ error, theme }) => theme.colors.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }

  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`

const AddBtn = styled(Button)`
  height: 40px;
  border-radius: 4px;
  color: ${dexTheme.colors.accent1};
`

const CustomInput = styled(CInput)`
  font-size: 0.9rem;
  border-color: white;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`

export default function AddressInputPanel({
  id,
  value,
  onChange,
}: {
  id?: string
  // the typed string value
  value: string
  // triggers whenever the typed value changes
  onChange: (value: string) => void
}) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const { address, loading, name } = useENS(value)
  const [recipients, setRecipients] = useState<any>([])
  const [contactsLimitReached, setContactsLimitReached] = useState(true)

  const [currentAddrIsRecipient, setCurrentAddrIsRecipient] = useState<boolean>(
    recipients.filter((r) => r.value === address).length > 0
  )
  const checkCurrentAddr = useCallback(() => {
    setCurrentAddrIsRecipient(recipients.filter((r) => r.value === address).length > 0)
  }, [address, recipients])

  useEffect(() => {
    const rcp = JSON.parse(localStorage.getItem('recipients') ?? '[]')
    setRecipients(rcp)
    setContactsLimitReached(rcp.length >= CONTACT_LIST_LIMIT)
  }, [address])

  function ContactList() {
    // TODO: improve selection performance
    const [selectedRecipient, setSelectedRecipient] = useState<any>(null)
    useEffect(() => {
      const currentRecipient = recipients.filter((r) => r.value === address)[0]
      // if recipient exists in contact list select label from list
      if (currentAddrIsRecipient) {
        setSelectedRecipient(currentRecipient)
      } else {
        setSelectedRecipient(null)
      }
    }, [selectedRecipient])

    // 0x490E6e9A0D02D9986F6A447E6f2303ef3387d752
    const handleSelectRecipient = useCallback((event) => {
      setSelectedRecipient(event)
      const input = event.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    }, [])
    return (
      <div>
        <Select
          defaultValue={selectedRecipient}
          value={selectedRecipient}
          onChange={handleSelectRecipient}
          options={recipients}
          placeholder="Select Recipient"
        />
      </div>
    )
  }

  function ContactListActions() {
    const [newRcpLabel, setNewRcpLabel] = useState('')

    useEffect(() => {
      setCurrentAddrIsRecipient(recipients.filter((r) => r.value === address).length > 0)
      setContactsLimitReached(recipients.length >= CONTACT_LIST_LIMIT)
    }, [])
    const handleRemoveRecipient = useCallback(() => {
      // remove active recipient from contact list
      const updatedRecipients = recipients.filter((r) => {
        return r.value !== address
      })
      localStorage.setItem('recipients', JSON.stringify(updatedRecipients))
      setRecipients(updatedRecipients)
      checkCurrentAddr()
    }, [])
    const handleAddRecipient = useCallback(() => {
      recipients.push({
        value: address,
        label: newRcpLabel.trim() === '' ? shortenAddress(address ?? '') : newRcpLabel,
      })
      localStorage.setItem('recipients', JSON.stringify(recipients))
      setRecipients(recipients)
      checkCurrentAddr()
    }, [newRcpLabel])
    return (
      <>
        {!contactsLimitReached && address && !currentAddrIsRecipient && (
          <div style={{ margin: '1rem auto auto', display: 'flex', alignItems: 'stretch', maxWidth: 'fit-content' }}>
            <CustomInput
              value={newRcpLabel}
              type="text"
              name="name"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onChange={(event) => {
                setNewRcpLabel(event.target.value)
              }}
              style={{ flexGrow: 1 }}
              placeholder="Label"
            />
            <AddBtn
              size="sm"
              style={{ fontSize: '0.9rem', borderRadius: '4px' }}
              variant="text"
              title="Add recipient to contact list"
              onClick={handleAddRecipient}
            >
              Add ({CONTACT_LIST_LIMIT - recipients.length})
            </AddBtn>
          </div>
        )}
        {address && currentAddrIsRecipient && (
          <div style={{ marginTop: '1rem' }}>
            <Button
              size="sm"
              style={{ fontSize: '0.9rem', borderRadius: '4px', color: `${dexTheme.colors.text2}` }}
              variant="text"
              title="Remove recipient from contact list"
              onClick={handleRemoveRecipient}
            >
              Remove Recipient
            </Button>
          </div>
        )}
      </>
    )
  }

  const handleInput = useCallback(
    (event) => {
      const input = event.target.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    },
    [onChange]
  )

  const error = Boolean(value.length > 0 && !loading && !address)

  return (
    <InputPanel id={id}>
      <ContainerRow error={error}>
        <InputContainer>
          <Grid container alignItems="center" textAlign="left" justifyContent="space-between">
            <Grid item md={3} sm={3} xs={6}>
              <Black color={theme.colors.textSubtle} fontWeight={500} fontSize={14}>
                Recipient
              </Black>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              {address && chainId && (
                <ExternalLink href={getEtherscanLink(chainId, name ?? address, 'address')} style={{ fontSize: '14px' }}>
                  (View on bscscan)
                </ExternalLink>
              )}
            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              <ContactList />
            </Grid>
            <Grid item md={12} sm={12} xs={12} marginTop="1rem">
              <Input
                style={{ textAlign: 'center' }}
                className="recipient-address-input"
                type="text"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                placeholder="Enter Wallet Address"
                error={error}
                pattern="^(0x[a-fA-F0-9]{40})$"
                onChange={handleInput}
                value={value}
              />
            </Grid>
            <Grid item md={12} sm={12} textAlign="center">
              <ContactListActions />
            </Grid>
          </Grid>
        </InputContainer>
      </ContainerRow>
    </InputPanel>
  )
}
